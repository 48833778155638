import { makeStyles } from "@material-ui/core/styles";

export const internalChatUseStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    minHeight: "48px",
  },
  drawerPaper: {
    ...theme.scrollbarStyles,
    position: (props) => (props.isExpanded ? "absolute" : "relative"),
    whiteSpace: "nowrap",
    width: (props) => props.drawerWidth,
    background: "#3d6929",
    color: "black",
    height: "100vh",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(255, 255, 255, 0.5) transparent",
    scrollPaddingBlockStart: "10px",
    scrollPaddingBlockEnd: "10px",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    width: (props) => props.drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: "10px",
    },

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  chatItem: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  avatar: {
    backgroundColor: "black",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
    color: theme.palette.primary.contrastText,
    marginRight: "10px",
    width: "50px",
    height: "50px",
  },
  avatarBadge: {
    "& .MuiBadge-badge": {
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
  truncateText: {
    color: "white",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "65%",
    display: "inline-block",
  },
  chatPreview: {
    color: "white",
    fontSize: "1rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "65%",
  },
  chatContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    overflow: "hidden",
  },
  messageContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  timestamp: {
    color: "black",
    fontSize: "0.8rem",
    alignSelf: "flex-end",
  },
  timestampChat: {
    color: "white",
    fontSize: "0.8rem",
    alignSelf: "flex-end",
  },
  sentMessage: {
    justifyContent: "flex-end",
  },
  receivedMessage: {
    justifyContent: "flex-start",
  },
  chatHeader: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    color: "white",
  },
  exitIcon: {
    color: "white",
    cursor: "pointer",
  },
  chatInputContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  chatInput: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#000000c9",
    color: "white",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
  },
  sendButton: {
    color: "white",
    backgroundColor: "transparent",
    cursor: "pointer",
    "&:disabled": {
      color: "#828282",
      backgroundColor: "transparent",
    },
  },
  exitIcon: {
    color: "white",
    cursor: "pointer",
    position: "absolute",
    left: 0,
    margin: "10px",
  },
  sentMessage: {
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    backgroundColor: "#dcf8c6",
    color: "black",
    padding: "10px",
    borderRadius: "10px",
    minWidth: "30%",
    maxWidth: "60%",
    margin: "5px",
    alignSelf: "flex-end",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",

    overflowWrap: "break-word",
    minWidth: 150,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  receivedMessage: {
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: "white",
    color: "black",
    padding: "10px",
    borderRadius: "10px",
    minWidth: "30%",
    maxWidth: "60%",
    margin: "5px",
    alignSelf: "flex-start",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",

    overflowWrap: "break-word",
    minWidth: 150,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  messageContainer: {
    display: "flex",
  },
  messageContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  searchInput: {
    width: "100%",
    color: "black",
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px",
  },
  tabContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    "& .PrivateTabIndicator-root-101": {
      backgroundColor: "white",
    },
  },
  tabItem: {
    color: "white",
    fontWeight: "bold",
    fontSize: "0.9rem",
    cursor: "pointer",
    width: "100%",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
    borderRadius: 0,
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    height: "70vh",
    overflow: "auto",
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    padding: "0px",
  },
  onlineAvatar: {
    border: "4px solid #00d0ff",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
  },
  offlineAvatar: {
    border: "4px solid #ff0000",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
  },
  badge: {
    bottom: "15px",
    left: "5px",
  },
  replyMessage: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#000000c9",
    color: "white",
    padding: "10px",
    borderRadius: "10px",
    margin: "5px",
    width: "100%",
  },
  closeButton: {
    color: "white",
    cursor: "pointer",
    alignSelf: "flex-end",
  },
  imageGridContainer: {
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  imageGridItem: {
    backgroundColor: "black",
    borderRadius: "10px",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
  },
  createGroupButton: {
    color: "white",
    backgroundColor: "#00427E",
    width: "100%",
    borderRadius: "0",
    "&:hover": {
      backgroundColor: "#00427E",
    },
  },
  messageName: {
    color: "black",
    fontSize: "0.8rem",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  audioRecorder: {
    display: "none",
  },
  rootBadge: {
    "& .MuiBadge-badge": {
      position: "fixed",
      top: "10px",
      right: "5px",
    },
  },
}));
